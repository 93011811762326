import React, { useState, useEffect } from "react";
import convert from "xml-js";
import "./App.css";

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [artist, setArtist] = useState("X RÁDIÓ");
  const [title, setTitle] = useState("ÉREZD MAGAD JÓL");
  const [artwork, setArtwork] = useState(
    "https://www.xradio.hu/artwork/default.jpg"
  );

  const get = async (url) => {
    const response = await fetch(url);

    if (response.status === 200) {
      try {
        const buffer = await response.arrayBuffer();
        const decoder = new TextDecoder("iso-8859-2");
        const text = decoder.decode(buffer);
        const json = convert.xml2js(text);

        const song =
          json?.elements?.[0]?.elements?.[0]?.elements?.filter(
            (f) => f.name === "Song"
          )?.[0] ?? {};

        const title = song?.attributes?.title ?? "ÉREZD MAGAD JÓL";
        const artist =
          song?.elements?.filter((f) => f.name === "Artist")?.[0]?.attributes
            ?.name ?? "X RÁDIÓ";

        const constCharTransformer = (char) => {
          switch (char) {
            case "á":
              return "a";
            case "í":
              return "i";
            case "ö":
              return "o";
            case "ó":
              return "o";
            case "ő":
              return "o";
            case "ü":
              return "u";
            case "ű":
              return "u";
            case "ú":
              return "u";
            case "é":
              return "e";
            default:
              return char;
          }
        };

        let a = "";
        for (var i = 0; i < artist.length; i++) {
          const char = artist[i];
          a = a + constCharTransformer(char);
        }

        let t = "";
        for (var i = 0; i < title.length; i++) {
          const char = title[i];
          t = t + constCharTransformer(char);
        }

        const url = encodeURI(`${a} - ${t}`);
        setTitle(title);
        setArtist(artist);
        setArtwork(`https://www.xradio.hu/artwork/${url}.jpg`);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

  useEffect(() => {
    get("https://xradio.hu/NowOnAir.xml");
    const recursiveFunction = async () => {
      await sleep(30000);
      await get("https://xradio.hu/NowOnAir.xml");
      recursiveFunction();
    };
    recursiveFunction();
  }, []);

  const playerController = () => {
    if (isPlaying) {
      setIsPlaying(false);
      document.querySelector("#xradioAudioPlayer").pause();
    } else {
      setIsPlaying(true);
      document.querySelector("#xradioAudioPlayer").play();
    }
  };

  return (
    <div className="App">
      <header className="App-header"></header>
      <div className="App-container">
        <div>
          <img srcset={artwork} className="artwork" />
          <p className="App-title">{artist}</p>
          <p className="App-title">{title}</p>
        </div>

        <audio
          id="xradioAudioPlayer"
          src="https://www.xradio.hu/play.php"
        ></audio>
        <div
          className={`App-player-button-${isPlaying ? "stop" : "play"}`}
          onClick={playerController}
        ></div>
      </div>
    </div>
  );
};

export default App;
